<template>
  <div class="login">
    <div class="circle-container" @click="handleClick">

      <div class="center-text">点击诊断</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$router.push({
        name: "FramePalm"
      });
    },
    speakText(text) {
      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'zh-CN';
        window.speechSynthesis.speak(utterance);
      } else {
        console.warn('当前浏览器不支持语音播报功能');
      }
    }
  },
  mounted() {
    // 页面加载完成后立即播报提示信息
    this.speakText('请点击开始诊断');
  }

};
</script>

<style scoped>
/* 确保父容器允许 .login 占据全部可用空间 */
.login {
  background-image: url('@/assets/image/图片3.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  flex-direction: column;
}

.circle-container {
  position: relative;
  width: 50%; 
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.center-text {
  position: absolute;
  font-size: 500%;
  font-weight: bold;
  color: #9e0b0b;
  z-index: 10;
}

@keyframes expand {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.3); /* 增大放大的比例 */
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
